import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
const H1Styles = css `
  ${fonts.DaxPro.Bold};
  font-size: 45px;
  letter-spacing: 0.02em;
  line-height: 48px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopLarge)} {
    font-size: 70px;
    line-height: 70px;
  }
`;
const H2Styles = css `
  ${fonts.DaxPro.Bold};
  font-size: 38px;
  letter-spacing: 0.02em;
  line-height: 40px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopLarge)} {
    font-size: 60px;
    line-height: 60px;
  }
`;
const H3Styles = css `
  ${fonts.DaxPro.Bold};
  font-size: 35px;
  letter-spacing: 0.02em;
  line-height: 42px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopLarge)} {
    font-size: 50px;
    line-height: 60px;
  }
`;
const H4Styles = css `
  ${fonts.DaxPro.Bold};
  font-size: 22px;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopLarge)} {
    font-size: 40px;
    line-height: 40px;
  }
`;
const H5Styles = css `
  ${fonts.DaxPro.Regular};
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 22px;

  @media ${from(Device.DesktopSmall)} {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 16px;
  }
`;
const H6Styles = css `
  ${fonts.DaxPro.Bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 20px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopLarge)} {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Heading = styled.h1 `
  ${({ visual }) => {
    switch (visual) {
        case 'h1':
            return H1Styles;
        case 'h2':
            return H2Styles;
        case 'h3':
            return H3Styles;
        case 'h4':
            return H4Styles;
        case 'h5':
            return H5Styles;
        case 'h6':
            return H6Styles;
        default:
            break;
    }
}}
`;
export default {
    // H1Styles,
    // H2Styles,
    H3Styles,
    H4Styles,
    H5Styles,
    // H6Styles,
    Heading,
};
