import styled, { css } from 'styled-components';
import { Device, from } from './media';
const baseGrid = css `
  --grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--grid-gap);
  max-width: 1920px;
  margin: 0 auto;

  max-width: 1920px;
  margin: 0 auto;

  @media ${from(Device.MobileLarge)} {
    --grid-gap: 12px;
  }

  @media ${from(Device.Tablet)} {
    --grid-gap: 15px;
  }

  @media ${from(Device.TabletLarge)} {
    --grid-gap: 20px;
  }

  @media ${from(Device.DesktopSmall)} {
    --grid-gap: 30px;
  }
`;
const Default = styled.div `
  ${baseGrid};
  --gridPadding: 13px;
  max-width: 1920px;
  margin: 0 auto;

  @media ${from(Device.MobileLarge)} {
    --gridPadding: 40px;
  }

  @media ${from(Device.Tablet)} {
    --gridPadding: 60px;
  }

  @media ${from(Device.TabletLarge)} {
    --gridPadding: 80px;
  }

  @media ${from(Device.DesktopSmall)} {
    --gridPadding: 100px;
  }

  @media ${from(Device.Desktop)} {
    --gridPadding: 120px;
  }

  @media ${from(Device.DesktopLarge)} {
    --gridPadding: 140px;
  }

  padding: 0px var(--gridPadding);
`;
const DefaultBreakout = css `
  position: relative;
  left: 0px;
  right: 0px;
  margin: 0px -13px;

  @media ${from(Device.MobileLarge)} {
    margin: 0px -40px;
  }

  @media ${from(Device.Tablet)} {
    margin: 0px -60px;
  }

  @media ${from(Device.TabletLarge)} {
    margin: 0px -80px;
  }

  @media ${from(Device.DesktopSmall)} {
    margin: 0px -100px;
  }

  @media ${from(Device.Desktop)} {
    margin: 0px -120px;
  }

  @media ${from(Device.DesktopLarge)} {
    margin: 0px -140px;
  }
`;
const DefaultPseudo = css `
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
  }

  &:before {
    left: -13px;
    width: 13px;

    @media ${from(Device.MobileLarge)} {
      left: -40px;
      width: 40px;
    }

    @media ${from(Device.Tablet)} {
      left: -60px;
      width: 60px;
    }

    @media ${from(Device.TabletLarge)} {
      left: -80px;
      width: 80px;
    }

    @media ${from(Device.DesktopSmall)} {
      left: -100px;
      width: 100px;
    }

    @media ${from(Device.Desktop)} {
      left: -120px;
      width: 120px;
    }

    @media ${from(Device.DesktopLarge)} {
      left: -140px;
      width: 140px;
    }
  }

  &:after {
    right: -13px;
    width: 13px;

    @media ${from(Device.MobileLarge)} {
      right: -40px;
      width: 40px;
    }

    @media ${from(Device.Tablet)} {
      right: -60px;
      width: 60px;
    }

    @media ${from(Device.TabletLarge)} {
      right: -80px;
      width: 80px;
    }

    @media ${from(Device.DesktopSmall)} {
      right: -100px;
      width: 100px;
    }

    @media ${from(Device.Desktop)} {
      right: -120px;
      width: 120px;
    }

    @media ${from(Device.DesktopLarge)} {
      right: -140px;
      width: 140px;
    }
  }
`;
const Inner = styled.div `
  ${baseGrid};
`;
const CardGridSpans = css `
  grid-column: span 6;

  @media ${from(Device.TabletLarge)} {
    grid-column: span 4;
  }

  @media ${from(Device.DesktopLarge)} {
    grid-column: span 3;
  }
`;
export const Grid = {
    Default,
    DefaultBreakout,
    DefaultPseudo,
    Inner,
    CardGridSpans,
};
