import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
const Card = styled.a `
  display: flex;
  flex-direction: column;
  background-color: ${brand.grey.grey89};
  color: ${brand.black};
  padding: 55px 12px 12px 12px;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  transition: transform 0.3s;

  @media ${from(Device.TabletLarge)} {
    padding: 60px 22px 22px 22px;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    transform: translateY(-10px);
    color: ${brand.black};
  }

  [data-featured='true'] & {
    color: ${brand.white};
    background-color: ${brand.grey.grey20};

    @media ${from(Device.TabletLarge)} {
      padding: 90px 22px 22px 22px;
    }
  }
`;
const TagArea = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;
const Tag = styled.span `
  padding: 2px 15px;
  background-color: ${brand.primary.alt};
  ${fonts.DaxPro.Bold};
  font-size: 16px;
  line-height: 28px;
  color: ${brand.white};
  margin-right: 10px;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    padding: 8px 17px;
  }
`;
const DivisionTag = styled.span `
  --tagColor: ${brand.divisionTags.default};
  padding: 2px 15px;
  background-color: var(--tagColor);
  ${fonts.DaxPro.Bold};
  font-size: 16px;
  line-height: 28px;
  color: ${brand.white};
 
  &[data-tag-colour='division-sandhurst'] {
    --tagColor: ${brand.divisionTags.sandhurst};
  }

  &[data-tag-colour='division-mrcropper'] {
    --tagColor: ${brand.divisionTags.mrCropper};
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    padding: 8px 17px;
  }
`;
const Media = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
`;
const Icon = styled.div `
  color: ${brand.grey.grey20};

  [data-featured='true'] & {
    color: ${brand.white};
  }

  > svg {
    display: block;
    width: 36px;
    height: 43px;
  }
`;
const Size = styled.span `
  ${fonts.DaxPro.Regular};
  font-size: 14px;
  margin-left: 12px;
  color: ${brand.grey.grey44};

  [data-featured='true'] & {
    color: ${brand.white};
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
  }
`;
const Name = styled.span `
  ${fonts.DaxPro.Bold};
  font-size: 16px;
  color: ${brand.grey.grey20};
  line-height: 28px;

  [data-featured='true'] & {
    color: ${brand.white};
  }

  [data-featured='true'] & {
    color: ${brand.white};
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;
const Content = styled.p `
  display: block;
  font-size: 18px;
  line-height: 28px;

  @media ${until(Device.TabletLarge)} {
    display: none;

    [data-featured='true'] & {
      display: block;
    }
  }
`;
const Footer = styled.div `
  flex: 1 0 auto;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  @media ${from(Device.TabletLarge)} {
    height: 55px;
  }

  > svg {
    display: block;
    width: 20px;
    height: 11px;
    stroke: ${brand.grey.grey20};
    transform: rotate(90deg);

    [data-featured='true'] & {
      stroke: ${brand.white};
    }
  }
`;
export const FileCardStyles = {
    Card,
    Content,
    Tag,
    TagArea,
    DivisionTag,
    Media,
    Icon,
    Size,
    Name,
    Footer,
};
